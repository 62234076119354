import React from 'react';
import { Container, Row, Col,Card } from 'react-bootstrap';
import img from '../../assets/Outsiders-Logo-Only_zeroBg.png';
import './home.css'
import YoutubeEmbed from './youtubeEmbed';

const Home = () => {
return (
	<Container style={{marginTop: "25px", marginBottom: "25px"}}>
		<div style={{textAlign: 'center', marginBottom: '25px'}}>
			<img src={img} style={{height: '20rem'}}/>
		</div>
		<h1 style={{textAlign: "center"}}>Welcome to Outsiders Group</h1>
		<br/>
		<div style={{textAlign: "center"}}>
			<p>		
				Welcome to Outsiders Group. We are an adventure company that is based near the Dinokeng Nature Reserve. 
				We pride ourselves in the work we do with each and every individual.
				Our motto is that no child is left behind, as everyone is important and has an incredible role to play in his life and in society.
			</p>
			<p>
				The values we try to pass onto others are those of integrity, love, respect and self acceptance. 
				Every person is unique in his own way, therefore we adjust the way we treat everyone in a way that the individual person can identify with. 
				We offer camps not only for the able, but we strive to cater for children and people from all walks of life.
			</p>
			<p>
				Our youth will forever be the key to our future, how we form and shape that key, will dictate how our future further unfolds. 
				Don’t let the future of our society become consumed by modern life and technology, a growing mind can always benefit from a day or two outdoors, or a night under the stars.
			</p>
			<p>
				We offer camps ranging from weekend camps, all the way to week and holiday camps and packages that won’t cost an arm and a leg.
				Feel free to peruse our website and see for yourself what we  have to offer and more.
			</p>
		</div>
		<YoutubeEmbed embedId="CEgvLLyG5Dk" />

		<br/>
		<h1 style={{textAlign: "center"}}>Our Activities</h1>
		<br/>
		<Row>
			<Col>
				<Card className='cssCard'>
					<Card.Img variant="top" src={process.env.PUBLIC_URL+"assets/images/activities/paintball.jpg"} />
					<Card.Body>
						<Card.Title>Paintball</Card.Title>
						<Card.Text>
							Whether you are 12 or 42, nobody has ever said no to a friendly game of paintball, why not enjoy it with us?
						</Card.Text>
					</Card.Body>
				</Card>
			</Col>
			<Col>
				<Card className='cssCard'>
					<Card.Img variant="top" src={process.env.PUBLIC_URL+"assets/images/activities/camping.jpg"} />
					<Card.Body>
						<Card.Title>Camping</Card.Title>
						<Card.Text>
							You see your kids only knowing the indoors, glued to the TV screen or a cellphone, why not let them explore the outdoors and what better way to achieve that by camping.
						</Card.Text>
					</Card.Body>
				</Card>
			</Col>
			<Col>
				<Card className='cssCard'>
					<Card.Img variant="top" src={process.env.PUBLIC_URL+"assets/images/activities/water_sports.jpg"} />
					<Card.Body>
						<Card.Title>Water Sports</Card.Title>
						<Card.Text>
							Your little ones will love swimming in a dam, cruising on the slip and slide, making war with water balloons or building a raft, they can experience that here with us!
						</Card.Text>
					</Card.Body>
				</Card>
			</Col>
		</Row>
		<br/>
		<Row>
			<Col>
				<Card className='cssCard'>
					<Card.Img variant="top" src={process.env.PUBLIC_URL+"assets/images/activities/zip_lining.jpg"} />
					<Card.Body>
						<Card.Title>Zip Lining</Card.Title>
						<Card.Text>
							If you want to get your little ones into the thrill of the great outdoors, there is nothing better than to enjoy zip lining and whooshing across our grounds on a suspended line, feeling the wind coursing through your body.
						</Card.Text>
					</Card.Body>
				</Card>
			</Col>
			<Col>
				<Card className='cssCard'>
					<Card.Img variant="top" src={process.env.PUBLIC_URL+"assets/images/activities/wall_climbing.jpg"} />
					<Card.Body>
						<Card.Title>Wall climbing and Abseiling</Card.Title>
						<Card.Text>
							Nothing builds a sense of adventure nor a healthy active lifestyle better than abseiling.
						</Card.Text>
					</Card.Body>
				</Card>
			</Col>
			<Col>
				<Card className='cssCard'>
					<Card.Img variant="top" src={process.env.PUBLIC_URL+"assets/images/activities/fishing.jpg"} />
					<Card.Body>
						<Card.Title>Fishing</Card.Title>
						<Card.Text>
							Fishing has through the ages been a great pass time, the wait and the thrill of the catch is what will entice anybody to become an Outsider.
						</Card.Text>
					</Card.Body>
				</Card>
			</Col>
		</Row>

	</Container>
);
};

export default Home;
