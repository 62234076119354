import React from 'react';
import './App.css';
import MainNavBar from './components/navbar';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/pages/home';
import About from './components/pages/about';
import Contact from './components/pages/contact';
import Gallery from './components/pages/gallery';
import Pricing from './components/pages/pricing/pricing';
import Footer from './components/footer/footer';

function App() {
return (
	<div className='main'>
		<Router>
		<MainNavBar />
		<Routes>
			<Route path="/" element={<Home/>} exact />
			<Route path='/about' element={<About/>} />
			<Route path='/gallery' element={<Gallery/>}/>
			<Route path='/pricing' element={<Pricing/>} />
			<Route path='/contact' element={<Contact/>} />
		</Routes>
		<Footer/>
		</Router>
	</div>
);
}

export default App;
