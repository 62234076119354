import React from 'react';
import Plans from './plans';
import "./pricing.css";

const Pricing = () => {

return (
	<div>
		<div className="pricing-header px-3 py-3 pt-md-5 pd-md-4 mx-auto text-center">
    		<h1 className="display-4">Pricing</h1>
    		<p className="lead">
				Standard activities include: Obstacle course, zip lining, slingshot, stalk the lantern, water sports, campfire activities, boere sport.
				Other packages will be determined by individual needs, times and age groups.
				Please feel free to get in touch with us via our contact page should you require more information or pricing.
    		</p>
  		</div>
		<div className="container">
			<Plans />
		</div>
	</div>
);
};

export default Pricing;
