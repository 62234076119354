import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Container} from 'react-bootstrap';
import MapComponent from '../shared/maps';
import './contact.css';
import ReCAPTCHA from "react-google-recaptcha";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";

const Contact = () => {
  let [validated, setValidated] = useState(true);

  const [form, setForm] = useState({});

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      })
  }

  const [errors, setErrors] = useState({});

  const SERVICE_ID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const TEMPLATE_ID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const USER_ID = process.env.REACT_APP_EMAILJS_USER_ID;


  function onChange(value) {
      console.log("Captcha value:", value);
      setValidated(false);
  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully"
        })
      }, (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        })
      });
    e.target.reset()
  };

  return (
    <Container style={{marginTop: "25px", marginBottom: "25px"}}>
      <MapComponent/>
      <br/>
      <Form onSubmit={handleOnSubmit}>
        <Row className="mb-3">
          <Form.Group as={Col} controlId="name">
            <Form.Label>Your Name (required)</Form.Label>
            <Form.Control name="from_name" placeholder="Enter your name" required />
          </Form.Group>

          <Form.Group as={Col} controlId="email">
            <Form.Label>Your Email (required)</Form.Label>
            <Form.Control name="from_email" type="email" placeholder="Enter your email" />
          </Form.Group>
        </Row>
        <Form.Group className="mb-3" controlId="subject">
          <Form.Label>Subject</Form.Label>
          <Form.Control name="subject" type="text" placeholder="Example: Camp dates enquiry" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="message">
          <Form.Label>Your Message</Form.Label>
          <Form.Control name="message" as="textarea" rows={3} />
        </Form.Group>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITEKEY}
          onChange={onChange}
        />
        <Button type="submit" className='btn-main mt-3' disabled={validated} >
          Submit
        </Button>
      </Form>
    </Container>
  );
};

export default Contact;
