import React from 'react';
import ImageGallery from 'react-image-gallery';
import CreateImageItems from '../shared/galleryContent';
import { Container } from 'react-bootstrap';
import "./gallery.css";

const Gallery = () => {
return (
	<Container style={{marginBottom: "50px", marginTop: "50px;"}}>
		<ImageGallery items={CreateImageItems()}/>
	</Container>
	
);
};

export default Gallery;
