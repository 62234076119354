import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';

const About = () => {
return (
	<Container style={{marginTop: "25px", marginBottom: "25px"}}>
		<Row style={{margin: "auto"}}>
			<Col md={12}>
				<h1 style={{textAlign: "center", paddingLeft: "20px"}}>About Us</h1>
			</Col>
		</Row>
		<Row style={{margin: "auto"}}>
			<Col style={{textAlign: "center", marginTop: "20px"}}>
				<p>
					Our Goal at OutsidersGroup is to provide a wholesome outdoor experience for your kids, we currently provide weekend camps for ages 10 to 18, with week and holiday camps coming in the near future, whether mom and dad need a weekend alone or you just want your child to not be glued to a television or cellphone screen all day, or maybe your child craves the outdoors.
				</p>
			</Col>
		</Row>	
		<Row style={{margin: "auto"}}>
			<Col style={{textAlign: "center"}}>
				<p>
					You can rest easy knowing that your kids are safe, taken care of and having the time of their lives.
				</p>
			</Col>
		</Row>
		<Row style={{margin: "auto"}}>
			<Col style={{textAlign: "center"}}>
				<p>
					We have staff trained in first aid as well as survival, we are also currently in training to be able to assist disabled children so they don’t miss out on any aspect of this amazing life we have been given.
				</p>
			</Col>
		</Row>
		<Row style={{margin: "auto"}}>
			<Col style={{textAlign: "center"}}>
				<p>
					To get in contact with us, simply go to our contact page, send us an email and we will gladly provide any and all info needed.
				</p>
			</Col>
		</Row>

		<Row style={{margin: "auto", marginTop: "25px"}}>
			<Col md={12}>
				<h3 style={{textAlign: "center", paddingLeft: "20px"}}>Meet the team</h3>
			</Col>
		</Row>

		<Row style={{marginBottom: "15px" }}>
			<Col md={4} style={{textAlign: "center"}}>
				<Image src={process.env.PUBLIC_URL+"assets/images/about/pieter.jpg"} style={{width: "250px", height: "250px", objectFit: "cover"}} roundedCircle />
			</Col>
			<Col xs={8} style={{marginTop: "auto", marginBottom: "auto"}}>
				<h3>Pieter</h3>
				<p>“…We can never have enough nature”. This quote so accurately describes what I believe in.
						I have been an outdoors man and adventurist my entire life. Always in search of the next adventure, the next lesson to learn.
						I am a firm believer that nature can teach all of us something if we just listen. My outlook on life is that loyalty and positivity is key to enrich peoples lives. I encourage people to seek peace and joy through nature the way I do.”
						</p></Col>
		</Row>

		<Row>
			<Col md={4} style={{textAlign: "center"}}>
				<Image src={process.env.PUBLIC_URL+"assets/images/about/daneel.jpg"} style={{width: "250px", height: "250px", objectFit: "cover"}} roundedCircle />
			</Col>
			<Col xs={8} style={{marginTop: "auto", marginBottom: "auto"}}>
				<h3>Daneel</h3>
				<p>“I am a man with a very positive outlook on life, I choose to be happy and see all the good in everything.
						I am someone with a lot of passion for fitness, sports and adventure. Being in the outdoors is one of the most exciting things for me and it definitely shaped my views in life.
						Respect is one of my main characteristics and I strive to make it a part of my everyday life, for it’s a core value that I really admire. A little bit less on the serious side, I am also a fun guy and I tend to make friends very easily.”
				</p>
			</Col>
		</Row>
	</Container>
);
};

export default About;
