import React from 'react';
// import {
// Nav,
// NavLink,
// Bars,
// NavMenu,
// NavBtn,
// NavBtnLink,
// Header
// } from './navbarElements';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const MainNavBar = () => {
return (
	<Navbar expand="lg" className="bg-body-tertiary">
		<Container>
		<Navbar.Brand href="/">
            Outsiders Group
          </Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" />
			<Navbar.Collapse id="basic-navbar-nav">
				<Nav className="me-auto" style={{textAlign: "center"}}>
				<Nav.Link href="/">Home</Nav.Link>
				<Nav.Link href="/about">About Us</Nav.Link>
				<Nav.Link href="/pricing">Pricing</Nav.Link>
				<Nav.Link href="/gallery">Gallery</Nav.Link>
				<Nav.Link href="/contact">Contact</Nav.Link>
				</Nav>
			</Navbar.Collapse>
		</Container>
	</Navbar>
	// <>
	// <Nav>
	// 	<Bars />
		
	// 	<NavMenu>
	// 	<NavLink to='/' activestyle="true">
	// 		Home
	// 	</NavLink>
	// 	<NavLink to='/about' activestyle="true">
	// 		About Us
	// 	</NavLink>
	// 	<NavLink to='/pricing' activestyle="true">
	// 		Pricing
	// 	</NavLink>
	// 	<NavLink to='/gallery' activestyle="true">
	// 		Gallery
	// 	</NavLink>
	// 	<NavLink to='/contact' activestyle="true">
	// 		Contact Us
	// 	</NavLink>
	// 	{/* Second Nav */}
	// 	{/* <NavBtnLink to='/sign-in'>Sign In</NavBtnLink> */}
	// 	</NavMenu>
	// </Nav>
	// </>
);
};

export default MainNavBar;
