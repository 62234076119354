import React from "react";
import {
	FooterContainer, FooterWrap, FooterLinksContainer,
	FooterLinkItems, FooterLinksWrapper, FooterLinkTitle,
	SocialMedia, SocialMediaWrap, SocialLogo, WebsiteRights, SocialIcons,
	SocialIconLink, 
	FooterText
} from "./footerElements";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube} from 'react-icons/fa';
import img from '../../assets/Outsiders-Logo-Only_zeroBg.png'

const Footer = () => {
return (
	<FooterContainer>
	<FooterWrap>
		<FooterLinksContainer>
			<FooterLinksWrapper>
				{/* <FooterLinkItems>
					<FooterLinkTitle>Navigation</FooterLinkTitle>
					<FooterLink to="/about">About Us</FooterLink>
					<FooterLink to="/pricing">Pricing</FooterLink>
					<FooterLink to="/gallery">Gallery</FooterLink>
					<FooterLink to="/contact">Contact</FooterLink>
				</FooterLinkItems> */}
				<FooterLinkItems>
					<FooterLinkTitle>Address</FooterLinkTitle>
					<FooterText>-25.478667, 28.459056</FooterText>
					<FooterText>Near the Dinokeng Nature Reserve</FooterText>
				</FooterLinkItems>
			</FooterLinksWrapper>
			<FooterLinksWrapper>
				<FooterLinkItems>
					<FooterLinkTitle>Contact Us</FooterLinkTitle>
					<FooterText>info@outsidersgroup.co.za</FooterText>
					<FooterText>076 422 6459</FooterText>
				</FooterLinkItems>
			</FooterLinksWrapper>
		</FooterLinksContainer>

		<SocialMedia>
			<SocialMediaWrap>
				<SocialLogo to='/' src={img} ></SocialLogo>
				<WebsiteRights> Outsiders Group {new Date().getFullYear()} All rights reserved</WebsiteRights>
				<SocialIcons>
					<SocialIconLink href="https://www.facebook.com/Outsiders-Group-305756043179924/" target="_blank"
					aria-label="Facebook">
						<FaFacebook />
					</SocialIconLink>
					<SocialIconLink href="https://instagram.com/group_outsiders?igshid=1e4k4drqbx18h" target="_blank"
					aria-label="Instagram">
						<FaInstagram />
					</SocialIconLink>
					<SocialIconLink href="https://twitter.com/group_outsiders" target="_blank"
					aria-label="Twitter">
						<FaTwitter />
					</SocialIconLink>
					<SocialIconLink href="https://www.youtube.com/@outsidersgroupza6167" target="_blank"
					aria-label="Youtube">
						<FaYoutube />
					</SocialIconLink>
				</SocialIcons>
			</SocialMediaWrap>
		</SocialMedia>
	</FooterWrap>
	
</FooterContainer>
	);
};
export default Footer;
